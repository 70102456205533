import Inputmask from 'inputmask';

export default (locale, currency) => ({
  locale: locale,
  currency: currency,

  globalBudget: 0,
  savings: 0,
  processed: false,

  formData: {
    natureOfPurchase: null,
    cost: null,
    isPurchaserInPrimeLocation: false,
    sponsoredBy: null,
  },

  /**
   * Initialize the component.
   */
  init() {
    Inputmask('currency', {
      numericInput: true,
      rightAlign: false,
      autoUnmask: true,
      groupSeparator: locale === 'fr' ? '.' : ',',
      digits: 0,
      onBeforeMask: function (value, opts) {
        if (null === value) { // fix charAt on null errors
          value = '0';
        }

        return value;
      },
    }).mask(this.$refs.cost);
  },

  /**
   * Reset the form ans calculate again.
   */
  resetForm() {
    this.formData.natureOfPurchase = null;
    this.formData.cost = null;
    this.formData.isPurchaserInPrimeLocation = false;
    this.formData.sponsoredBy = null;

    this.globalBudget = 0;
    this.savings = 0;
    this.processed = false;
  },

  /**
   * Calculate results.
   */
  calculate() {
    let budget = this.taxes();
    let commissions = this.appCommissions();

    if (this.formData.sponsoredBy === 'APRIL' || this.formData.sponsoredBy === 'NETVOX') {
      this.savings = this.calculateSavingsByPartner(commissions);
      this.globalBudget = (budget + (commissions - this.savings));
    } else {
      this.globalBudget = budget + commissions;
    }

    this.processed = true;
  },

  /**
   * Calculate cost savings if partner is sponsoring.
   *
   * @param {Number} budget
   */
  calculateSavingsByPartner(budget) {
    return budget * 0.10;
  },

  /**
   * Format cost to currency format.
   *
   * @param cost
   */
  formatCost(cost) {
    return new Intl.NumberFormat(this.locale === 'fr' ? 'de-DE' : this.locale, {
      style: 'currency',
      currency: this.currency.code,
    }).format(cost);
  },

  /**
   * Calculate taxes.
   */
  taxes() {
    if (this.formData.natureOfPurchase === 'shares') {
      return this.formData.cost * 0.001;
    }

    if (this.formData.cost <= 23_000) {
      return 25;
    }

    let taxPercentageBuyerLocation = this.formData.isPurchaserInPrimeLocation ? 0.01 : 0.03;

    if (this.formData.cost > 23_000 && this.formData.cost <= 107_000) {
      return (this.formData.cost - 23_000) * taxPercentageBuyerLocation;
    }

    if (this.formData.cost > 107_000 && this.formData.cost <= 200_000) {
      return (84_000 * taxPercentageBuyerLocation) + ((this.formData.cost - 107_000) * 0.03);
    }

    if (this.formData.cost > 200_000) {
      return (84_000 * taxPercentageBuyerLocation) + (93_000 * 0.03) + ((this.formData.cost - 200_000) * 0.05);
    }
  },

  /**
   * Commission to be received by assurdeal.
   *
   * @return {Number}
   */
  appCommissions() {
    if (this.formData.cost >= 0 && this.formData.cost <= 50_000) {
      return this.formData.cost * 0.10;
    }

    if (this.formData.cost > 50_000 && this.formData.cost <= 250_000) {
      return (50_000 * 0.10) + ((this.formData.cost - 50_000) * 0.07);
    }

    if (this.formData.cost > 250_000 && this.formData.cost <= 50_0000) {
      return (50_000 * 0.10) + (200_000 * 0.07) + ((this.formData.cost - 250_000) * 0.05);
    }

    if (this.formData.cost > 500_000) {
      return this.formData.cost * 0.06;
    }
  },

  /**
   * Form is valid.
   *
   * @return {null}
   */
  isValid() {
    return (this.formData.natureOfPurchase && this.formData.cost);
  },

  /**
   * Text show how much savings received from the sponsor.
   *
   * @param baseText
   */
  sponsorSavingsText(baseText) {
    baseText = baseText.replace(':partner', this.formData.sponsoredBy);
    baseText = baseText.replace(':savings', this.formatCost(this.savings));

    return baseText;
  },
});
