import find from 'lodash/find';

export default ({ locale, totalIntermediaries, regions, contractTypes }) => ({
  locale: locale,
  regions: regions,
  contractTypes: contractTypes,
  totalIntermediaries: totalIntermediaries,
  formData: {
    region: null,
    contractType: null,
  },
  results: null,

  /**
   * Get selected region object.
   *
   * @return Object|null
   */
  get selectedRegion() {
    if (!this.formData.region) {
      return {
        id: '',
        label: '',
        value: 0,
      };
    }

    let selectedRegion = find(this.regions, { id: this.formData.region });

    if (!selectedRegion) {
      return {
        id: '',
        label: '',
        value: 0,
      };
    }

    return selectedRegion;
  },

  /**
   * Get selected contract type object.
   *
   * @return Object
   */
  get selectedContractType() {
    if (!this.formData.contractType) {
      return {
        id: '',
        label: '',
        value: 0,
      };
    }

    let selectedContractType = find(this.contractTypes, { id: this.formData.contractType });

    if (!selectedContractType) {
      return {
        id: '',
        label: '',
        value: 0,
      };
    }

    return selectedContractType;
  },

  /**
   * Determine if the form is valid.
   *
   * @return {boolean}
   */
  get isValid() {
    return (
      this.formData.region !== null &&
      this.formData.contractType !== null
    );
  },

  /**
   * Formatted number for number of intermediaries.
   *
   * @return {String}
   */
  get formattedNumberOfIntermediaries() {
    if (!this.isValid) {
      return '';
    }

    return new Intl.NumberFormat(this.locale === 'fr' ? 'de-DE' : this.locale).format(
      this.selectedRegion.value
    );
  },

  /**
   * Calculate results.
   */
  calculate() {
    if (this.isValid) {
      this.results = Math.floor(this.selectedContractType.value * (this.selectedRegion.value / this.totalIntermediaries));
    }
  },

  /**
   * Reset the form and calculate again.
   */
  resetForm() {
    this.formData.region = null;
    this.formData.contractType = null;
    this.results = null;
  },
});
