/**
 * Format currency.
 *
 * @param {Number} amount
 * @param {string} code
 * @param {string} locale
 *
 * @return {string}
 */
export function formatCurrency(amount, code, locale) {
  return new Intl.NumberFormat(locale === 'fr' ? 'de-DE' : locale, {
    style: 'currency',
    currency: code,
  }).format(amount);
}
