export default (deadline, labels) => ({
  deadline,
  labels,
  remaining: null,

  init() {
    this.setRemaining();

    setInterval(() => {
      this.setRemaining();
    }, 1000);
  },

  setRemaining() {
    const diff = new Date(this.deadline) - new Date().getTime();
    this.remaining = parseInt(diff / 1000);
  },

  days() {
    return {
      value: this.remaining / 86400,
      remaining: this.remaining % 86400,
    };
  },

  hours() {
    return {
      value: this.days().remaining / 3600,
      remaining: this.days().remaining % 3600,
    };
  },

  minutes() {
    return {
      value: this.hours().remaining / 60,
      remaining: this.hours().remaining % 60,
    };
  },

  format(value) {
    return ('0' + parseInt(value)).slice(-2);
  },

  split(value) {
    return this.format(value).split('');
  },

  times() {
    return [
      {
        remaining: this.split(this.days().value),
        label: this.labels.day,
      },
      {
        remaining: this.split(this.hours().value),
        label: this.labels.hour,
      },
      {
        remaining: this.split(this.minutes().value),
        label: this.labels.min,
      },
    ];
  },
});
