export default (title = '', message = '', type = null, initialOpenState = false) => ({
  title: title,
  message: message,
  shown: false,
  type: type,

  toggle() {
    this.shown = !this.shown;
  },

  open() {
    this.shown = true;
    setTimeout(() => {
      this.shown = false;
    }, 6000);
  },

  init() {
    if (initialOpenState === true) {
      setTimeout(() => this.open(), 400);
    }
  },
});
