import get from 'lodash/get';
import find from 'lodash/find';
import Inputmask from 'inputmask';
import pmt from './../../utils/pmt';
import { formatCurrency } from '../../utils/numberFormat';

export default ({ locale, currency, clienteles, contractTypes }) => ({
  locale: locale,
  currency: currency,
  clienteles: clienteles,
  contractTypes: contractTypes,
  processed: false,
  formData: {
    expectedMonthlyIncome: null,
    clientele: null,
    contractType: null,
    financialSupport: 0,
  },

  /**
   * Determine if the form is valid.
   *
   * @return {boolean}
   */
  get isValid() {
    return (
      this.formData.expectedMonthlyIncome &&
      this.formData.clientele &&
      this.formData.contractType &&
      this.formData.financialSupport !== null
    );
  },

  /**
   * Calculate the average investment.
   *
   * @return {Number}
   */
  get averageInvestment() {
    if (!this.isValid) {
      return 0;
    }

    let clienteleObject = find(this.clienteles, { id: this.formData.clientele });

    if (!clienteleObject) {
      return 0;
    }

    let minCoefficient = get(clienteleObject, ['coefficients', this.formData.contractType, 'min']);
    let maxCoefficient = get(clienteleObject, ['coefficients', this.formData.contractType, 'max']);
    let averageCoefficient = ((minCoefficient + maxCoefficient) / 2);


    return Math.floor(12 * this.formData.expectedMonthlyIncome * averageCoefficient);
  },

  /**
   * Calculate net investment cost.
   *
   * @return {Number}
   */
  get netInvestment() {
    if (this.averageInvestment >= 500_000) {
      return (this.averageInvestment * 1.11) - 3_190;
    }

    if (this.averageInvestment >= 250_000) {
      return (this.averageInvestment * 1.11) + 1_810;
    }

    if (this.averageInvestment >= 200_000) {
      return (this.averageInvestment * 1.12) - 3_910;
    }

    if (this.averageInvestment >= 50_000) {
      return (this.averageInvestment * 1.10) + 810;
    }

    if (this.averageInvestment >= 23_833.33) {
      return (this.averageInvestment * 1.13) - 690;
    }

    return (this.averageInvestment * 1.10) + 25;
  },

  /**
   * Calculate total costs for taxes and fees.
   *
   * @return {Number}
   */
  get taxAndFeesCost() {
    if (this.averageInvestment >= 500_000) {
      return (this.averageInvestment * 0.11) - 3_190;
    }

    if (this.averageInvestment >= 250_000) {
      return (this.averageInvestment * 0.11) + 1_810;
    }

    if (this.averageInvestment >= 200_000) {
      return (this.averageInvestment * 0.12) - 3_910;
    }

    if (this.averageInvestment >= 50_000) {
      return (this.averageInvestment * 0.10) + 810;
    }

    if (this.averageInvestment >= 23_833.33) {
      return (this.averageInvestment * 0.13) - 690;
    }

    return (this.averageInvestment * 0.10) + 25;
  },

  /**
   * Calculate the borrowed amount.
   *
   * @return {Number}
   */
  get amountBorrowed() {
    if (!this.isValid) {
      return 0;
    }

    let financialSupport = Math.floor(this.netInvestment * (this.formData.financialSupport / 100));

    return this.netInvestment - financialSupport;
  },

  /**
   * Get monthly net income for the first year.
   *
   * @return {Number}
   */
  get monthlyNetIncome() {
    if (!this.isValid) {
      return 0;
    }

    return Math.floor(
      pmt(0.01 / 12, 84, this.amountBorrowed, 0, 0) + this.formData.expectedMonthlyIncome
    );
  },

  /**
   * Calculate results.
   */
  calculate() {
    if (this.isValid) {
      this.processed = true;
    }
  },

  /**
   * Reset the form ans calculate again.
   */
  resetForm() {
    this.processed = false;

    this.formData.expectedMonthlyIncome = null;
    this.formData.clientele = null;
    this.formData.contractType = null;
    this.formData.financialSupport = 0;
  },

  /**
   * Format currency value.
   *
   * @param {Number} amount
   * @return {string}
   */
  formattedCurrency(amount) {
    return formatCurrency(amount, this.currency.code, this.locale);
  },

  /**
   * Initialize the component.
   */
  init() {
    Inputmask('currency', {
      numericInput: true,
      rightAlign: false,
      autoUnmask: true,
      groupSeparator: locale === 'fr' ? '.' : ',',
      digits: 0,
      onBeforeMask: function (value, opts) {
        if (null === value) { // fix charAt on null errors
          value = '0';
        }

        return value;
      },
    }).mask(this.$refs.expected_monthly_income);
  },
});
